<template>
    <div>
        <ConfirmDialog></ConfirmDialog>
        <Card>
            <template #content>
                <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
                <form @submit='save' @change='formIsDirty=true'>
                    <div class='grid'>
                        <div class='field col-12'>
                            <Toolbar class='mt-2'>
                                <template v-slot:start>
                                    <div>
                                        <span class='publish_state'>Stato di pubblicazione</span>
                                        <Dropdown
                                            v-model='v$.model.publish_state.$model'
                                            :options='publish_states'
                                            optionLabel='label'
                                            optionValue='code'
                                            :class="{'p-invalid': v$.model.publish_state.$invalid && v$.model.publish_state.$dirty}"
                                            placeholder='Stato di pubblicazione' />
                                    </div>
                                </template>
                                <template v-slot:end>
                                    <div>
                                        <span class='publish_state'>Ordine </span>
                                        <InputNumber id='m_rank'
                                                   v-model='v$.model.rank.$model'
                                                   :class="{'p-invalid': v$.model.rank.$errors.length}"
                                        />
                                    </div>
                                </template>
                            </Toolbar>
                        </div>
                    </div>
                    <div class='grid p-fluid formgrid mt-3'>
                        <div class='field col-12'>
                            <div class='p-inputgroup'>
                                <span class='p-float-label'>
                                    <InputText id='t_slug'
                                               type='text'
                                               v-model='v$.model.slug.$model'
                                               :class="{'p-invalid': v$.model.slug.$invalid && v$.model.slug.$dirty}"
                                    />
                                    <Button label='Fix' class='p-button-warning' title='Correggi SLUG'
                                            @click='model.slug = sluggify(model.slug)' />
                                    <label for='t_slug'>Slug</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class='grid p-fluid formgrid mt-3'>
                        <div class='field col-12  md:col-8'>
                            <span class='p-float-label'>
                                <InputText id='title_chap'
                                           type='text'
                                           v-model='v$.model.title.$model'
                                           :class="{'p-invalid': v$.model.title.$errors.length}"
                                />
                                <label for='title_chap'>Titolo</label>
                            </span>
                        </div>
                    </div>
                    <div class='grid p-fluid formgrid mt-3'>
                        <div class='field col-12 html-editor-wrap p-float-label'>
                            <HtmlEditor
                                v-model:content='model.description'
                                @update:content='formIsDirty=true'
                                :key='"cont_sk2_desc_"+model.id'
                                toolbar='essential'
                                contentType='html' />
                            <label>Descrizione</label>
                        </div>
                    </div>

                    <div class='grid p-fluid formgrid mt-3'>
                        <div class='field col-12'>
                            <SkillModuleBooks
                                title='Libri che fanno parte di questo modulo'
                                :books='model.book_ids'
                                :slugs='slugs'
                                @book-added='addBook'
                                />
                        </div>
                    </div>
                </form>
                <div class='grid'>
                    <div class='col-12 text-center'>
                        <ResetButton @click='resetConfirm' />
                        <SaveButton @click='save' />
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import ResetButton from '../../../../components/4books/forms/ResetButton.vue';
import SkillModuleBooks from '../../../../components/4books/SkillModuleBooks.vue';
import tools from '../../../../libs/tools';
import useVuelidate from '@vuelidate/core';
import model from './model';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('Cont/SkillsV2');

const commonStore = createNamespacedHelpers('Common');
import Navigations from '../../../../mixins/navigations';
import Notifications from '../../../../mixins/notifications';

export default {
    components: { ResetButton, SkillModuleBooks },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return model.modelValidation;
    },
    data() {
        return {
            formIsDirty: false,
            model: {},
        };
    },
    watch: {
        module(n) {
            this.copyModel(n);
        },
    },
    emits: ['reset'],
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['row']),
        ...commonStore.mapGetters(['publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
        slugs(){
            if (this.row && this.row.books) return this.row.books;
            return {};
        }
    },
    mounted() {
        this.copyModel(this.module);
    },
    methods: {
        ...mapActions(['saveModule', 'addModuleBooks']),
        async addBook(book){
            const bm = {
                module_id: this.model.id,
                skill_id: this.model.skill_id,
                locale: this.model.locale,
                rank: 0,
                book_id: book.id
            };
            try {
                await this.addModuleBooks(bm);
                this.ShowSuccess('Ottimo', 'Libro aggiunto con successo');
            } catch (e) {
                this.ShowApiError(e);
            }
        },
        copyModel(mod) {
            const books = [];
            if (mod['book_ids'] && mod['book_ids'].length) {
                mod['book_ids'].forEach(bm => {
                    bm.order = bm.rank || 1000000;
                    if (!bm.rank) bm.rank = 0;
                    books.push({
                        ...bm,
                    });
                });
            }
            const base = model.modelCopy();
            this.model = {
                ...base,
                ...mod,
                book_ids: books,
            };
        },
        async save(){
            this.saveModule(this.model)
                .then(() => {
                    this.ShowSuccess('Ottimo', 'Modulo salvato con successo');
                    this.exit();
                }).catch(this.ShowApiError);
        },
        exit() {
            this.$emit('reset');
        },
        resetConfirm() {
            this.$confirm.require({
                message: 'Uscendo senza salvare perderai tutte le modifiche. Sei sicuro?',
                header: 'Conferma l\'uscita',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.exit();
                    this.$confirm.close();
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
    },
    mixins: [Navigations, Notifications],
};
</script>
