<template>
    <div>
        <ConfirmDialog></ConfirmDialog>
        <h5>{{ model.title }}</h5>
        <div v-html='model.description'>
        </div>
        <div class='grid p-fluid formgrid mt-3'>
            <div class='field col-12 text-left'>
                <DataTable
                    :value='books'
                    sortField='order'
                    :sortOrder='1'
                    class='mt-3 p-shadow-1 p-datatable-sm'>
                    <template #empty>
                        Non ci sono libri correlati.
                    </template>
                    <Column header='Ordine' :style="{width:'44px'}">
                        <template #body='{data}'>
                            <InputNumber v-model='data.rank' :style="{width:'40px'}"
                                         @update:modelValue='updateRank(data)' />
                        </template>
                    </Column>
                    <Column header='Titolo' :style="{minWidth:'40%'}">
                        <template #body='{data}'>
                            <i v-if='hasProblems(data)' class='pi pi-fw pi-exclamation-triangle' style='color: red' :title='hasProblems(data)'></i>
                            <router-link target='_blank' :to="'/app/cont/books/'+data['book_id']+'/'+data['locale']">{{ getTitle(data) }}</router-link>
                            <Tag class='ml-3' v-if='hasProblems(data)' severity="danger" :value="hasProblems(data)"></Tag>
                        </template>
                    </Column>
                    <Column header='Type' :style="{minWidth:'3em'}">
                        <template #body='{data}'>
                            <Tag v-if='data.rank > 0' class='mr-2' icon='pi pi-exclamation-mark' severity='info'
                                 value='featured'></Tag>
                        </template>
                    </Column>
                    <Column header='' :style="{width:'10%'}">
                        <template #body='{data}'>
                            <Tag class="cursor-pointer" title='Elimina' icon="pi pi-trash ml-1" severity="danger" @click='removeBook(data)'></Tag>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('Cont/SkillsV2');
import Notifications from '../../../../mixins/notifications';

export default {
    mounted() {
        this.resetRankMap(this.model);
    },
    methods: {
        ...mapActions(['patchModuleBooks', 'deleteModuleBook']),
        getTitle(row) {
            if (this.slugs && this.slugs[row.book_id]) {
                return this.slugs[row.book_id];
            }
            return row.book_id;
        },
        hasProblems(row){
            if (this.problems && this.problems[row.book_id]) {
                return this.problems[row.book_id];
            }
            return ""
        },
        resetRankMap(n){
            this.rankMap = {};
            if (n.book_ids){
                n.book_ids.forEach(book => {
                    this.rankMap[book['book_id']] = book['rank'] || 0;
                })
            }
        },
        removeBook(mod){
            this.$confirm.require({
                message: 'Sei sicuro di voler eliminare il libro dal modulo?',
                header: 'Conferma l\'eliminazione',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doRemove(mod);
                    this.$confirm.close();
                },
                reject: () => {
                    this.$confirm.close();
                },
            });

        },
        async doRemove(mod){
            try {
                await this.deleteModuleBook(mod);
            } catch (e) {
                this.ShowApiError(e);
            }
        },
        async updateRank(mod) {
            if (this.rankMap[mod['book_id']] === mod['rank']) return;
            try {
                if (mod.rank !== 0){
                    for (const book of this.books) {
                        if (book.book_id !== mod.book_id && book.rank >= mod.rank) {
                            book.rank++;
                            await this.patchModuleBooks({bookModule: book, doMutation: false});
                        }
                    }
                }
                await this.patchModuleBooks({bookModule: mod, doMutation: true});
            } catch (e) {
                this.ShowApiError(e);
            }


            this.ShowSuccess('Ottimo', 'Libri aggiornati con successo');
        },
    },
    mixins: [Notifications],
    data() {
        return {
            rankMap: {}
        };
    },
    computed: {
        books(){
            if (this.model.book_ids){
                return this.model.book_ids.map(book => {
                    if (!book.rank) book.rank = 0;
                    book.order = book.rank;
                    if (book.order === 0) book.order = 1000000;
                    return book;
                });
            }
            return [];
        }
    },
    watch: {
        model(n){
            this.resetRankMap(n);
        }
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        slugs: {
            type: Object,
            default() {
                return {};
            },
        },
        problems: {
            type: Object,
            default() {
                return {};
            },
        },

    },
};
</script>
