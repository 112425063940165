import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: 0,
    last_update: 0,
    slug: "",
    icon: "",
    translations: {}
};
/*

const module = {
    skill_id: 0,
    locale: "",
    slug: "",
    title: "",
    summary: "",
    description: "",
    publish_state: "",
    image: "",
    last_update: 0,
    rank: 0
};
*/

const translation = {
    id: 0,
    last_update: 0,
    slug: "",
    skill_id: 0,
    locale: "",
    img: "",
    title: "",
    subtitle: "",
    publish_state: "",
    description: "",
    guest_desc: "",
    benefits: "",
    modules: []
};

export default {
    ...tools.models.apiCommons(model, translation, "skill_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            }
        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            title: { required },
            publish_state: { required }
        }
    },
    uploads: [
        { el: "uppyImg", target: "img", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ],
    uploadsPlain: [
        { el: "uppyIcon", target: "icon", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ]
};
