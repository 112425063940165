import tools from '../../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: 0,
    skill_id: 0,
    locale: "",
    slug: "",
    title: "",
//    summary: "",
    description: "",
    publish_state: "",
//    image: "",
    last_update: 0,
    book_ids: [],
    rank: 0
};

const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, "skill_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            rank: {},
            publish_state: { required },
            title: {required}
        }
    },
    translationValidation: {},
};
